<template>
  <div
    id="i-layout-navbar-refresh"
    class="i-layout-navbar-refresh"
    @click="handleRefresh"
  >
    <svg
      class="refresh"
      viewBox="0 0 1024 1024"
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
    >
      <path
        d="M934.4 206.933333c-17.066667-4.266667-34.133333 6.4-38.4 23.466667l-23.466667 87.466667C797.866667 183.466667 654.933333 96 497.066667 96 264.533333 96 74.666667 281.6 74.666667 512s189.866667 416 422.4 416c179.2 0 339.2-110.933333 398.933333-275.2 6.4-17.066667-2.133333-34.133333-19.2-40.533333-17.066667-6.4-34.133333 2.133333-40.533333 19.2-51.2 138.666667-187.733333 232.533333-339.2 232.533333C298.666667 864 138.666667 706.133333 138.666667 512S300.8 160 497.066667 160c145.066667 0 277.333333 87.466667 330.666666 217.6l-128-36.266667c-17.066667-4.266667-34.133333 6.4-38.4 23.466667-4.266667 17.066667 6.4 34.133333 23.466667 38.4l185.6 49.066667c2.133333 0 6.4 2.133333 8.533333 2.133333 6.4 0 10.666667-2.133333 17.066667-4.266667 6.4-4.266667 12.8-10.666667 14.933333-19.2l49.066667-185.6c0-17.066667-8.533333-34.133333-25.6-38.4z"
        p-id="3500"
      ></path>
    </svg>
  </div>
</template>

<script setup>
import { nextTick } from 'vue'
import { useRouter, useRoute } from 'vue-router'
const route = useRoute() // 当前路由信息
const router = useRouter() // 路由实例

const handleRefresh = () => {
  const { path } = route // 当前路由地址
  nextTick(() => {
    router.replace({ path: '/redirect' + path }) // 重定向到当前路由地址
  })
}
</script>

<style lang="less" scoped>
.i-layout-navbar-refresh {
  display: inline-block;
  cursor: pointer;
  line-height: 64px;
  color: #515a6e;
  padding: 0px 10px;

  .refresh {
    display: inline-block;
    vertical-align: middle;
    width: 16px;
    height: 16px;
    fill: currentColor;
    margin-top: -2px;
    transition: transform 0.28s linear;
  }
}
</style>
