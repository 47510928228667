import request from '../request'
import qs from 'qs'

// 用户登录
export function login(data) {
  // const formData = qs.stringify(data)
  return request({
    url: '/user/login',
    method: 'post',
    data
  })
}

//获取用户权限
export function getUserAuthority() {
  return request({
    url: '/user/self/permissions',
    method: 'get'
  })
}

//退出登录
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}
