import { tableDelApi } from '@/api/modules/common.js'
export function modalSure(delfromData) {
  return new Promise((resolve, reject) => {
    let content = ''
    if (delfromData.info !== undefined) {
      content = `<p>${delfromData.title}</p><p>${delfromData.info}</p>`
    } else if (delfromData.tips !== undefined) {
      content = `<p>${delfromData.tips}</p>`
    } else {
      content = `<p>确定要${delfromData.title}吗？</p><p>${delfromData.title}后将无法恢复，请谨慎操作！</p>`
    }
    this.$Modal.confirm({
      title: delfromData.title,
      content: content,
      loading: true,
      onOk: () => {
        setTimeout(() => {
          this.$Modal.remove()
          if (delfromData.success) {
            delfromData.success
              .then(async (res) => {
                resolve(res)
              })
              .catch((res) => {
                reject(res)
              })
          } else {
            tableDelApi(delfromData)
              .then(async (res) => {
                resolve(res)
              })
              .catch((res) => {
                reject(res)
              })
          }
        }, 300)
      },
      onCancel: () => {
        // this.$Message.info('取消成功');
      }
    })
  })
}

export function getFileType(fileName) {
  // 后缀获取
  let suffix = ''
  // 获取类型结果
  let result = ''
  try {
    const flieArr = fileName.split('.')
    suffix = flieArr[flieArr.length - 1]
  } catch (err) {
    suffix = ''
  }
  // fileName无后缀返回 false
  if (!suffix) {
    return false
  }
  suffix = suffix.toLocaleLowerCase()
  // 图片格式
  const imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif']
  // 进行图片匹配
  result = imglist.find((item) => item === suffix)
  if (result) {
    return 'image'
  }
  // 匹配 视频
  const videolist = [
    'mp4',
    'm2v',
    'mkv',
    'rmvb',
    'wmv',
    'avi',
    'flv',
    'mov',
    'm4v'
  ]
  result = videolist.find((item) => item === suffix)
  if (result) {
    return 'video'
  }
  // 其他 文件类型
  return 'other'
}
