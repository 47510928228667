import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import ViewUIPlus from 'view-ui-plus' //引入ViewUIPlus
// import ElementPlus from 'element-plus' //引入element
// import 'view-ui-plus/dist/styles/viewuiplus.css'

//引入vxe-table
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

import 'virtual:svg-icons-register' // 引入svg icon注册脚本
import SvgIcon from '@/components/SvgIcon/index.vue'

// import global index.less
import '@/styles/index.less'
import './assets/iconfont/iconfont.css'
import './assets/iconfontYI/iconfontYI.css'

import './permission'

import { modalSure, getFileType } from '@/utils/public.js' //公共方法

// 引入全局指令
import * as directives from '@/directives/index.js'

//注册全局组件
import PageTitle from '@/components/PageTitle/index.vue'
import PageTable from '@/components/PageTable/index.vue'
import ColorIcon from '@/components/ColorIcon/index.vue'
import ILink from '@/components/ILink/index.vue'

function useTable(app) {
  app.use(VXETable)
}

self.MonacoEnvironment = {
  getWorkerUrl: function (moduleId, label) {
    if (label === 'json') {
      return '/monaco-editor/min/vs/language/json/json.worker.js'
    }
    if (label === 'css') {
      return '/monaco-editor/min/vs/language/css/css.worker.js'
    }
    if (label === 'html') {
      return '/monaco-editor/min/vs/language/html/html.worker.js'
    }
    if (label === 'typescript' || label === 'javascript') {
      return '/monaco-editor/min/vs/language/typescript/ts.worker.js'
    }
    return '/monaco-editor/min/vs/editor/editor.worker.js'
  }
}

const app = createApp(App)
app.config.globalProperties.$modalSure = modalSure
app.config.globalProperties.$getFileType = getFileType
app.use(ViewUIPlus) //挂载ViewUIPlus
app.use(store) //挂载vuex
app.use(router) //挂载路由
// app.use(ElementPlus)
app.use(useTable) //挂载vxe-table
app.component('SvgIcon', SvgIcon) //注册全局组件
app.component('PageTitle', PageTitle) //注册全局组件
app.component('PageTable', PageTable) //注册全局组件
app.component('ColorIcon', ColorIcon) //注册全局组件
app.component('ILink', ILink) //注册全局组件
app.mount('#app') //挂载到#app

//注册自定义指令
Object.keys(directives).forEach((key) => {
  //Object.keys() 返回一个数组，值是所有可遍历属性的key名
  app.directive(key, directives[key]) //key是自定义指令名字；后面应该是自定义指令的值，值类型是string
})
