import { defineStore } from 'pinia' //引入pinia
import { login, logout, getUserAuthority } from '@/api/modules/login' //引入登录接口
import { getToken, setToken, removeToken } from '@/utils/auth' //引入token
import usePermissionStore from '@/store/modules/permission' //引入路由权限
import useTabsViewStore from '@/store/modules/tabsView' //引入tabsView
import companyList from '@/store/modules/companyList'
import { getCompanyList } from '@/api/modules/housing'

// 递归删除空的 children
function pruneEmptyChildren(menu) {
  if (Array.isArray(menu) && menu.length > 0) {
    for (let i = menu.length - 1; i >= 0; i--) {
      const item = menu[i]
      if (item.children && item.children.length === 0) {
        // 删除空的 children
        delete item.children
      } else if (item.children && item.children.length > 0) {
        // 递归处理子菜单
        pruneEmptyChildren(item.children)
      }
    }
  }
}

const useUserStore = defineStore('user', {
  state: () => ({
    token: getToken(),
    userInfo: null, //用户信息
    roles: [], //角色信息
    menuList: [], //菜单列表
    permissions: [] //按钮权限
  }),
  persist: {
    storage: localStorage,
    paths: ['userInfo', 'roles', 'menuList', 'permissions'], //只缓存这四个数据
    debug: true //是否开启debug模式
  },
  actions: {
    // 登录
    login(userInfo) {
      const { username, password } = userInfo
      return new Promise((resolve, reject) => {
        login({
          username: username.trim(),
          password
        })
          .then(async (res) => {
            const { access_token, name, role, telephone } = res
            if (access_token) {
              // 设置 token
              this.token = 'Bearer ' + access_token // 注意这里的空格
              setToken('Bearer ' + access_token) // 注意这里的空格
              // 设置用户信息
              this.userInfo = {
                name,
                telephone
              }
              // 获取路由权限信息
              // await this.getUserAuthority()

              //获取公司来源
              const company = companyList()
              // if (!company.companyList.length) {
              try {
                const list = await getCompanyList({ page: 1, size: 999, tp: 0 })
                company.setCompanyList(list.data.list)
              } catch (error) {
                console.log(error)
              }
              // }
            }
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },

    //获取用户权限信息
    getUserAuthority() {
      return new Promise((resolve, reject) => {
        getUserAuthority()
          .then((res) => {
            const { menus, permissions, roles } = res
            this.permissions = permissions // 按钮权限
            // set menuList
            this.menuList = menus // 菜单列表
            // 删除空的 children
            pruneEmptyChildren(this.menuList)
            this.roles = roles // 角色信息
            resolve(res)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    // 退出系统
    logout() {
      return new Promise((resolve, reject) => {
        this.token = ''
        this.userInfo = null
        this.roles = []
        this.menuList = []
        this.permissions = []
        //清除token
        removeToken()
        sessionStorage.clear()
        localStorage.clear()
        //清除路由
        const permissionStore = usePermissionStore()
        permissionStore.resetRoutes()
        //清除useTabsViewStore缓存
        const TabsViewStore = useTabsViewStore()
        TabsViewStore.delAllViews()
        resolve()
      })
    }
  }
})

export default useUserStore
