<template>
  <div id="i-layout-navbar-user" class="i-layout-navbar-user">
    <Dropdown class="avatar-dropdown" transfer-class-name="overlay-menu">
      <Avatar class="avatar-image" size="small" :src="avatar" />
      <span class="avatar-title">{{ name }}</span>
      <template #list>
        <DropdownMenu>
          <router-link to="/">
            <DropdownItem> 首页 </DropdownItem>
          </router-link>
          <!-- <router-link to="/user"> -->
          <!-- <router-link to="/">
            <DropdownItem>个人中心</DropdownItem>
          </router-link> -->
          <DropdownItem divided @click="passwordModal = true">修改密码</DropdownItem>
          <DropdownItem divided @click="handleLogout">退出登录</DropdownItem>
        </DropdownMenu>
      </template>
    </Dropdown>
    <Modal v-model="passwordModal" title="修改密码" footer-hide>
      <div style="margin-bottom: 10px">
        <Input password type="password" v-model="oldPassword" placeholder="请输入旧密码" @input="removeSpaces('oldPassword')" />
      </div>
      <div style="margin-bottom: 10px">
        <Input type="password" password v-model="newPassword" placeholder="请输入密码" @input="removeSpaces('newPassword')" />
      </div>
      <div style="text-align: right">
        <Button class="mr15" @click="passwordModal = false">取消</Button>
        <Button type="primary" @click="changePassword" :loading="loading">确认</Button>
      </div>
    </Modal>
  </div>
</template>
<script setup>
import { ref, computed } from 'vue'
import { Message, Modal, Input } from 'view-ui-plus'
import useUserStore from '@/store/modules/user'
import { useRoute, useRouter } from 'vue-router'
import { changePasswordApi } from '@/api/modules/setting.js'

const userStore = useUserStore()
const route = useRoute()
const router = useRouter()

// 用户名
const name = computed(() => {
  if (userStore.userInfo) {
    return userStore.userInfo.name
  } else {
    return 'User'
  }
})
// 头像
const avatar = computed(() => {
  if (userStore.userInfo && userStore.userInfo.avatar) {
    return userStore.userInfo.avatar
  } else {
    return 'https://dev-file.iviewui.com/userinfoPDvn9gKWYihR24SpgC319vXY8qniCqj4/avatar'
  }
})
// 修改密码
const passwordModal = ref(false)
const oldPassword = ref('')
const newPassword = ref('')
const loading = ref(false)

const removeSpaces = (field) => {
  if (field === 'oldPassword') {
    oldPassword.value = oldPassword.value.replace(/\s+/g, '')
  } else if (field === 'newPassword') {
    newPassword.value = newPassword.value.replace(/\s+/g, '')
  }
}
// 修改密码
const changePassword = () => {
  loading.value = true
  changePasswordApi({ oldPassword: oldPassword.value, newPassword: newPassword.value })
    .then((res) => {
      loading.value = false
      Message.success('密码修改成功！')
      passwordModal.value = false
      oldPassword.value = ''
      newPassword.value = ''
    })
    .catch((error) => {
      loading.value = false
    })
}

//退出
const handleLogout = () => {
  Modal.confirm({
    title: '退出登录',
    content: '<p>是否确认退出登录，清除登录信息？</p>',
    onOk: () => {
      userStore.logout().then((res) => {
        // router.push(`/admin/login?redirect=${route.fullPath}`)
        router.push(`/admin/login`)
        Message.success('退出登录成功')
      })
    },
    onCancel: () => {}
  })
}
</script>
<style lang="less" scoped>
// 登录用户信息
.i-layout-navbar-user {
  display: inline-block;
  padding: 0px 10px;
  .avatar-dropdown {
    cursor: pointer;
    .avatar-image {
      border-radius: 50%;
      vertical-align: middle;
    }
    .avatar-title {
      font-size: 14px;
      position: relative;
      margin-left: 10px;
    }
  }
}
</style>
